<template>
    <div>
        <template v-for="(navMenu, index) in navMenus">
            <el-menu-item v-if="navMenu.children==null" :key="`menu-item` + navMenu.id" :index="navMenu.path">
                <i :class="navMenu.icon"></i>
                <span slot="title">{{navMenu.title}}</span>
            </el-menu-item>

            <el-submenu v-if="navMenu.children" :key="`submenu` + navMenu.id" :index="navMenu.id">
                <template slot="title">
                    <i :class="navMenu.icon"></i>
                    <span>{{navMenu.title}}</span>
                </template>
                <nav-menu :navMenus="navMenu.children"></nav-menu>
            </el-submenu>
        </template>
    </div>
</template>

<script>
export default {
    name: 'nav-menu',
    props: {
      navMenus: {
        type: Array,
        default: []
      },
    },
    data() {
      return {

      }
    },
    methods: {}
}
</script>