<template>
    <!-- Header -->
    <el-header class="header">
        <el-drawer
            class="mobile-menu"
            title=""
            :visible.sync="drawer"
            size="70%"
            direction="ltr">
          <language class="language-menu" @on-lang-change="setLocal" :lang="local"/>
          <el-menu class="nav-item" :default-active="$route.path" mode="vertical" @select="handleSelect" router>
            <template v-for="(menu, i) in menus">
              <el-menu-item :index="menu.path" v-if="!menu.children">{{menu.title}}</el-menu-item>

              <el-submenu :index="menu.path" v-else>
                <template slot="title">{{menu.title}}</template>
                <el-menu-item v-for="(item, index) in menu.children" :index="item.path">{{item.title}}</el-menu-item>
              </el-submenu>
            </template>
          </el-menu>

          <ul class="subsidiary" v-if="header.subsidiary && header.subsidiary.list">
            <li v-for="(subsidiary, i) in header.subsidiary.list" :key="`subsidiary` + i">
              <a @click="gotoSubsidiary(subsidiary.url)">{{subsidiary[lang + 'title']}}</a>
            </li>
          </ul>
        </el-drawer>

        <div class="container">
          <h1>
            <!--<router-link :to="{ path: '/home' }">-->
            <img src="@/assets/logo.png" class="logo" @click="goto">
            <!--</router-link>-->
            <!-- 修改地址对话框 -->
            <!-- <el-cascader
              placeholder="搜索"
              :options="cityData"
              expandTrigger="hover"
              v-model="address"
              filterable>
            </el-cascader> -->
            <!-- <el-select v-model="address" filterable placeholder="请选择">
              <el-option
                v-for="item in cityData"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select> -->
          </h1>
          <div class="menu">

            <div class="icon_menu" @click="drawer = true" style="display: none;">
              <a class="i_menu"></a>
            </div>

            <div class="nav">
              <!--<el-menu class="nav-item" :default-active="$route.path" mode="horizontal" @select="handleSelect" router>
                <el-menu-item :index="item.path" v-for="item in $t('menu') " :key="`menu` + item.path">{{item.title}}</el-menu-item>
                &lt;!&ndash;<nav-menu class="nav-item" :navMenus="menus"></nav-menu>&ndash;&gt;
              </el-menu>-->

              <el-menu class="nav-item" :default-active="$route.path" mode="horizontal" @select="handleSelect" router>
                <template v-for="(menu, i) in menus">
                  <el-menu-item :index="menu.path" v-if="!menu.children">{{menu.title}}</el-menu-item>

                  <el-submenu :index="menu.path" v-else>
                    <template slot="title">{{menu.title}}</template>
                    <el-menu-item v-for="(item, index) in menu.children" :index="item.path">{{item.title}}</el-menu-item>
                  </el-submenu>
                </template>
              </el-menu>

              <!--<el-input
                class="search"
                :placeholder="$t('tips.enter')"
                @keyup.enter.native="search"
                v-model="searchText">
                <i slot="suffix" class="el-input__icon el-icon-search" @click="search"></i>
              </el-input>-->
              <language class="language" @on-lang-change="setLocal" :lang="local"/>
              <!--<el-button class="messages" type="text" @click="messageShow" v-if="isLogin">
                <el-badge :value="messages" :hidden="messages !== 0 ? false : true">
                  <i class="el-icon-chat-square"></i>
                </el-badge>
              </el-button>-->

              <!--<router-link :to="{ name: 'login'}" class="login" type="text" v-if="!isLogin">
                <i class="el-icon-user"></i>{{$t('login')}}
              </router-link>-->

              <el-dropdown
                  @command="console"
                  class="user-info"
                  v-if="isLogin">
                <span class="el-dropdown-link">
                  <img :src="userInfo.avatar">
                  {{userInfo.userName}}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-turn-off" :command="{ path: '/console' }">
                    管理中心
                  </el-dropdown-item>
                  <!--<el-dropdown-item icon="el-icon-chat-dot-square" :command="{ name: 'console_message' }">
                    <el-badge :value="messages" :hidden="messages !== 0 ? false : true">消息中心</el-badge>
                  </el-dropdown-item>
                  <el-dropdown-item icon="el-icon-star-off" :command="{ path: '/' }">
                    收藏
                  </el-dropdown-item>-->
                  <el-dropdown-item icon="el-icon-back" :command="'logout'">
                    退出
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>

              <ul class="subsidiary" v-if="header.subsidiary && header.subsidiary.list">
                <li v-for="(subsidiary, i) in header.subsidiary.list" :key="`subsidiary` + i">

                  <el-tooltip class="item" effect="dark" :content="subsidiary[lang + 'title']" placement="top-start">
                    <a @click="gotoSubsidiary(subsidiary.url)">{{subsidiary[lang + 'title']}} </a>
                  </el-tooltip>

                </li>
              </ul>
            </div>
          </div>


        </div>


        <!--<el-drawer
          class="message-view"
          title="消息中心"
          :visible.sync="messageDrawer"
          size="25%"
          direction="rtl">
          <div class="message-list">
            <transition-group name="el-fade-in-linear" v-if="messages !== 0">
              <div class="message-inner" v-for="(item, index) in message" :key="item.id">
                <div class="message-info">
                    <span class="message-info-title">{{item.title}}</span>
                    <span class="message-info-time">{{item.date}}</span>
                    <p class="message-info-content">{{item.text}}</p>
                </div>
                <div class="message-read" @click="messageRead(index)">标记已读</div>
              </div>
            </transition-group>
            <el-link type="info" :underline="false" v-else>暂无消息通知</el-link>
          </div>
        </el-drawer>-->
    </el-header>
</template>


<script>
// 引入地址数据
// import cityData from '@/assets/js/citydata.js'
import { mapMutations, mapActions } from 'vuex';
import NavMenu from '@/components/Menu'
// 国际化
import Language from '@/components/Language'
const langMenu = 'page.console.menu.';
import {getOption} from '@/api/os/admin';

export default {
  name: 'app-header',
  data() {
    return {
      drawer: false,
      optionName: 'page-menu',
      activeIndex: this.$route.path,
      // 搜索内容
      searchText: '',
      // 消息
      messageDrawer: false,
      // 城市数据
      // cityData,
      // address: [],
      userInfo: {
        nickname: '',
        userName: '',
        avatar: require('@/assets/images/top.jpg'),
      },
      isLogin: false,
      header: {},

      // 消息中心数据
      messages: 2,
      message: [
        {
          id: 1,
          title: '新消息',
          date: '2020-07-05 13:32:11',
          text: '消息提示123',
          status: 1,
        },
        {
          id: 2,
          title: '第二条',
          date: '2020-07-06 13:32:11',
          text: '消息提示',
          status: 1,
        }
      ]
    }
  },
  components: {
    Language,
    NavMenu
  },
  computed: {
    lang() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    },
    local () {
      return this.$store.state.app.local
    },
    menus() {
      let list = [
        { id: 'home', path: this.header.home, title: this.$t('home')},
        { id: 'golden_sun', path: '/goldenSun/', title: this.$t('page.console.menu.golden_sun')},
        { id: 'solution', path: '/solution/', title: this.$t('page.console.menu.solution')},
        { id: 'product', path: '/productCenter/', title: this.$t('page.console.menu.product_center')},
        { id: 'technical_support', path: '/technicalSupport/', title: this.$t('page.console.menu.technical_support')},
        { id: 'investor', path: '/investor/', title: this.$t('page.console.menu.investor')},
        { id: 'media', path: '/media/', title: this.$t('page.console.menu.media')},
      ]

      let data = this.header

      list.forEach((e, i) => {
        if(data[e.id] && data[e.id].list && data[e.id].list.length > 0) {
          let children = []
          data[e.id].list.forEach((m, index) => {
            let path = e.path + m.url
            children.push({
              id: e.id + '-' + index,
              title: m.title,
              en_title: m.en_title,
              path: path,
            })
          })
          e.children = children;
        }
      })

      return list;
    }
  },
  methods: {
    ...mapMutations([
      'setLocal'
    ]),
    ...mapActions([
      'handleLogOut'
    ]),
    ...mapActions([
      'getMenus'
    ]),
    init () {
      // 设置初始语言
      this.setLocal(this.$i18n.locale)
      let userInfo = this.$store.state.user.userInfo
      if(userInfo) {
        this.userInfo.username = userInfo.username
        this.userInfo.nickname = userInfo.nickname
        if(userInfo.userData.head_img) this.userInfo.avatar = userInfo.userData.head_img
        this.isLogin = true
      } else {
        this.isLogin = false
      }
    },
    // 点击菜单
    handleSelect(key, keyPath) {
      this.drawer = false
      console.log(key, keyPath);
    },
    // 退出
    logout() {
      this.handleLogOut().then( (res) => {
        this.$message(res.msg)
        this.isLogin = false
        this.$router.go(0)
      })
    },
    console(command) {
      if(command === 'logout') {
        this.logout()
        return
      }
      this.$router.push(command)
    },
    // 显示消息中心
    messageShow() {
      this.messageDrawer = true
    },
    // 已读
    messageRead(index) {
      this.message.splice(index, 1)
      this.messages--
    },
    // search
    search() {
      if(this.searchText) {
        console.log(this.$store.state.user.userInfo)
        this.$message.success(this.searchText)
      } else{
        this.$message.error(this.$t('tips.search'))
      }
    },
    goto() {
      // this.$router.push({
      //   path: '/home',
      // })
      window.location = '/'
    },
    gotoSubsidiary(id) {
      this.drawer = false
      this.$router.push({
        name: 'productCenter',
        query: {
          type: 'subsidiary',
          id: id
        }
      })
    }
  },
  created() {
    let menus = this.$store.state.user.menus
    if(menus) {
      this.header = menus.header
    } else {
      this.getMenus().then((res) => {
        if(res && res.header) {
          this.header = res.header
        }
      })
    }
  },
  mounted () {
    this.init()
  },

}
</script>

<style lang="less" scoped>
.header{
  .menu{
    //width: 1100px;
    //float: left;
    float: right;
  }
  .subsidiary{
    width: 180px;
    float: left;
    margin-left: 20px;
    li{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      :hover{
        color: #00923F;
      }
    }
  }
}
.el-header{
    width: 100%;
    background-color: #ffffff;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
.messages{
  float: left;
  height: 60px;
  padding: 0 12px;
  cursor: pointer;
  .el-badge{
    font-size: 2rem;
    padding: 0;
    color: #8492a6;
  }
}
.login{
  float: right;
  height: 60px;
  line-height: 60px;
  padding-left: 18px;
  font-size: 1.2rem;
  color: #409EFF;
  i{
    font-size: 1.4rem;
    margin-right: 6px;
  }
  a{
    color: #409eff;
  }
}
.el-dropdown-menu .el-badge{
  margin-left: 4px;
}
.search{
  width: 260px;
  float: left;
  line-height: 60px;
  margin-right: 20px;
}
.language{
  float: left;
  cursor: pointer;
}
.language-menu{
  width: 100%;
  height: 40px;
  padding-left: 20px;
}
.mobile-menu{
  .subsidiary{
    width: 100%;
    overflow: auto;
    margin-left: 40px;
    li{
      margin-top: 10px;
    }
  }
}
</style>