<template>
  <!-- Footer -->
  <el-footer class="footer padding-0">
    <div class="site-info">
      <el-row class="container">
        <el-col :xs="24" :sm="24" :md="24" :lg="16" :xl="16">
          <el-row>
            <el-col :xs="8" :sm="8" :md="24" :lg="8" :xl="8">
              <el-card shadow="never">
                <div slot="header" class="clearfix">
                  <span>{{$t('page.console.menu.solution')}}</span>
                </div>
                <div class="nav-items">
                  <ul>
                    <li class="nav-item" v-for="(solution, i) in footer.solution.list" :key="`solution` + i">
                      <a class="nav-link" :href="solution.url">{{solution[lang + 'title']}}</a>
                    </li>
                  </ul>
                </div>
                <!--<el-row>
                  <el-col :span="24" class="footer-text">在主题笔记，很轻松就能成为一位销售者或者创作者，将自己的数字作品更广泛的销售。这一切只需要简单的提交创作者申请即可完成。</el-col>
                </el-row>-->
              </el-card>
            </el-col>

            <el-col :xs="8" :sm="8" :md="12" :lg="7" :xl="7">
              <el-card shadow="never">
                <div slot="header" class="clearfix">
                  <span>{{$t('page.console.menu.product')}}</span>
                </div>
                <div class="nav-items">
                  <ul>
                    <li class="nav-item" v-for="(product, i) in footer.product.list" :key="`product` + i">
                      <a class="nav-link" :href="product.url">{{product[lang + 'title']}}</a>
                    </li>
                  </ul>
                </div>
              </el-card>
            </el-col>

            <el-col :xs="8" :sm="8" :md="12" :lg="7" :xl="7">
              <el-card shadow="never">
                <div slot="header" class="clearfix">
                  <span>{{$t('page.console.menu.about_us')}}</span>
                </div>
                <div class="nav-items">
                  <ul>
                    <li class="nav-item" v-for="(about, i) in footer.about.list" :key="`about` + i">
                      <a class="nav-link" :href="about.url">{{about[lang + 'title']}}</a>
                    </li>
                  </ul>
                </div>
              </el-card>
            </el-col>

            <!--<el-col :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
              <el-card shadow="never">
                <div slot="header" class="clearfix">
                  <span>订阅</span>
                </div>
                <el-row>
                  <el-col :span="24" class="footer-text">
                    <el-tabs v-model="activeName">
                      <el-tab-pane label="邮箱" name="mail">
                        <el-form
                            ref="subscribeRef"
                            :model="subscribeForm"
                            :rules="subscribeFormRules">
                          <el-form-item prop="email">
                            <el-input v-model="subscribeForm.email" size="small" placeholder="请输入邮箱..."></el-input>
                          </el-form-item>
                        </el-form>
                        <el-button class="subscribe" type="primary" icon="el-icon-plus" size="medium">订阅</el-button>
                      </el-tab-pane>
                      <el-tab-pane label="手机" name="tel">
                        <el-form
                            ref="subscribeRef"
                            :model="subscribeForm"
                            :rules="subscribeFormRules">
                          <el-form-item prop="tel">
                            <el-input v-model="subscribeForm.tel" size="small" placeholder="请输入电话..."></el-input>
                          </el-form-item>
                        </el-form>
                        <el-button class="subscribe" type="primary" icon="el-icon-plus" size="medium">订阅</el-button>
                      </el-tab-pane>
                    </el-tabs>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>-->
          </el-row>
        </el-col>

        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="8">
          <el-card shadow="never">
            <div slot="header" class="clearfix">
              <span>{{$t('page.console.menu.contact_us')}}</span>
            </div>
            <div class="nav-items">
              <ul>
                <li class="nav-item" v-for="(contact, i) in footer.contact.list" :key="`contact` + i">
                  <a class="nav-link  font-rem-12">{{contact[lang + 'title']}}</a>
                </li>

                <li class="nav-item">
                  <a class="nav-link">{{$t('page.home.email')}}：</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link">{{footer.contact.email}}</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link">{{$t('page.home.address')}}：</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link">{{footer.contact[lang + 'address']}}</a>
                </li>
              </ul>
            </div>
            <!--<el-row>
              <el-col :span="24" class="footer-text">在主题笔记，很轻松就能成为一位销售者或者创作者，将自己的数字作品更广泛的销售。这一切只需要简单的提交创作者申请即可完成。</el-col>
            </el-row>-->
          </el-card>
        </el-col>
      </el-row>
    </div>

    <div class="copyright">
     <a style="color: #ffffff;" :href="footer.copyright.url">{{footer.copyright[lang + 'name']}}</a>
    </div>

    <!-- <el-backtop target=".el-scrollbar__wrap"></el-backtop> -->
  </el-footer>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';

export default {
  data() {
    // 验证邮箱规则
    var checkEmail = (rule, value, callback) => {
      // 验证邮箱正则
      const regEmail = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/
      if(regEmail.test(value)){
        return callback()
      }
      callback(new Error('请输入合法的邮箱'))
    }
    // 验证手机号规则
    var checkMobile = (rule, value, callback) => {
      // 验证手机号正则
      const regMObile = /^1[3|4|5|6|7|8|9]\d{9}$/
      if(regMObile.test(value)){
        return callback()
      }
      callback(new Error('请输入合法的手机号'))
    }
    return {
      footer: {
        solution: {
          list: {}
        },
        product: {
          list: {}
        },
        about: {
          list: {}
        },
        contact: {
          title: '',
          en_title: '',
          email: '',
          address: '',
          en_address: '',
          list: {}
        },
      },
      // 订阅
      subscribeForm: {
        email: '',
        tel: ''
      },
      activeName: 'mail',
      // 表单验证
      subscribeFormRules: {
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        tel: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' }
        ]
      },
    }
  },
  computed: {
    // add
    lang() {
      if(this.$i18n.locale == 'zh-CN') {
        return ''
      }
      return 'en_';
    }
  },
  created() {
    let menus = this.$store.state.user.menus
    if(menus) {
      this.footer = menus.footer
    } else {
      this.getMenus().then((res) => {
        if(res && res.footer) {
          this.footer = res.footer
        }
      })
    }
  },
  methods: {
    ...mapActions([
      'getMenus'
    ]),
  }
}
</script>

<style lang="less">
.footer{
  .site-info{
    background-image: url('~@/assets/images/footer-bg.png');
  }
  .el-card{
    background-color: unset;
    .el-card__header{
      border: 0;
    }
  }
  .copyright{
    width: 100%;
    //height: 50px;
    line-height: 50px;
    text-align: center;
    background: rgba(29, 29, 29, 0.9);
  }
  .is-never-shadow{
    background-color: transparent !important;
  }
}
</style>