<template>
  <div class="language">
    <el-button type="text" @click="selectLang('zh-CN')" :class="{'active': this.$i18n.locale == 'zh-CN'}">中</el-button> /
    <el-button type="text" @click="selectLang('en-US')" :class="{'active': this.$i18n.locale == 'en-US'}">EN</el-button>
    <!--<el-dropdown trigger="click" @command="selectLang">
        <span class="el-dropdown-link">
            {{title}}<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(value, key) in localList" :name="key" :key="`lang-${key}`" :command="key">{{ value }}</el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>-->
  </div>
</template>

<script>
export default {
  name: 'Language',
  props: {
    lang: String
  },
  data () {
    return {
      langList: {
        'zh-CN': '语言',
        'zh-TW': '語言',
        'en-US': 'Lang'
      },
      localList: {
        'zh-CN': '中文(简体)',
        'zh-TW': '中文(繁体)',
        'en-US': 'English'
      }
    }
  },
  watch: {
    lang (lang) {
      // this.$message.success(this.localList[lang])
      this.$notify({
        title: this.localList[lang],
        type: 'success',
        position: 'bottom-right',
        duration: 3000
      });
      this.$i18n.locale = lang
    }
  },
  computed: {
    title () {
      return this.langList[this.lang]
    }
  },
  methods: {
    // 选择的语种
    selectLang (name) {
        this.$emit('on-lang-change', name)
    }
  }
}
</script>

<style lang="less">
.language{
  .el-button{
    font-size: 16px;
  }
}
</style>